<template>
	<div class="container">
		<a-spin :spinning="loading">
			<!--            扫码登录-->
			<div class="qr-code-view" v-if="isqr">
				<div class="qr-code">
					<a @click="qrclick" title="客户端登录"><img src="/assets/images/login/pwd_login.svg"/></a>
				</div>

				<div class="qr-img">
					<img src="/assets/images/login/qr_login.png" alt="二维码" width="200" height="200" />
				</div>
				<div class="no-qr-title">
					新功能准备中，敬请期待
				</div>
			</div>
			<!--            表单登录-->
			<div class="view" v-else>
				<div class="qr-code">
					<img src="/assets/images/login/fast_login.svg" />
					<a @click="qrclick" :title="l('Scanthecodetologinquickly')" style="margin-right: 10px"><img src="/assets/images/login/qrcode.svg"/></a>
				</div>
				<div style="text-align: center">
					<a-input
						:placeholder="l('AccountName')"
						class="from-input"
						v-model="userNameOrEmailAddress"
						ref="username"
						@pressEnter="$refs.pwd.focus()"
					/>
				</div>
				<div class="from-pwd">
					<a-input-password :placeholder="l('Password')" v-model="password" @pressEnter="login" ref="pwd" />
				</div>
				<div class="pwd-helper">
					<div class="remember-pwd ">
						<!-- <a-checkbox>
							{{l('Rememberpassword')}}
						</a-checkbox> -->
						<a-checkbox v-model="isAD" :checked="isAD" @change="ADLogin" v-if="isShowLDAP">
							{{l('ADAccountLogin')}}
						</a-checkbox>
					</div>
					<div class="forget-pwd">
						<div :title="l('ForgotPassword')"><span @click="forgetpw">{{l('ForgotPassword')}}</span></div>
					</div>
				</div>
				<div class="from-save">
					<a-button type="primary" @click="login" :loading="saving">
						{{l('LogIn')}}
					</a-button>
				</div>
			</div>
		</a-spin>
	</div>
</template>

<script>
import AppComponentBase from '../../../../shared/component-base/app-component-base';
import { loginService } from '../../login.service';

export default {
	name: 'login-form',
	mixins: [AppComponentBase],
	props:{
		isShowLDAP:[],
	},
	data() {
		return {
			isqr: false,
			userNameOrEmailAddress: undefined,
			password: undefined,
			isAD: false
		};
	},
	created() {},
	mounted() {
		//给个初始化的光标
		this.$refs.username.focus();
		if(localStorage.getItem('ISADLogin') == 'true') {
			this.isAD = true;
		}else if(localStorage.getItem('ISADLogin') == 'false') {
			this.isAD = false;
		}
	},
	methods: {
		qrclick() {
			this.isqr = !this.isqr;
		},
		forgetpw() {
			this.$emit('forgetpw', { viewkey: 4 });
		},
		ADLogin() {
			localStorage.setItem("ISADLogin",this.isAD)
		},
		/**
		 * 登录
		 */
		login() {
			if (!this.userNameOrEmailAddress) {
				return abp.message.warn('用户名不能为空');
			}
			if (!this.password) {
				return abp.message.warn('密码不能为空');
			}
			loginService.authenticateModel.userNameOrEmailAddress = this.userNameOrEmailAddress;
			loginService.authenticateModel.password = this.password;
			loginService.authenticateModel.clientType = 1;
			this.loading = true;
			this.saving = true;
			if(this.isAD) {
				loginService.authenticateToLdap(
					(res) => {
						if (res.shouldResetPassword) {
							this.$emit('loginChange', { data: res });
						}
					},
					() => {},
					() => {
						this.loading = false;
						this.saving = false;
					}
				);
			}else {
				loginService.authenticate(
					(res) => {
						if (res.shouldResetPassword) {
							this.$emit('loginChange', { data: res });
						}
					},
					() => {},
					() => {
						this.loading = false;
						this.saving = false;
					}
				);
			}
		},
	},
};
</script>
<style scoped></style>
<style scoped lang="less">
@import './login-form.less';
</style>
